import { TextField, Typography } from '@mui/material';
import { GridContainer, GridItem, Select, PercentTextField } from '../../../components/shared';
import useStyles from '../style';
import { INDEPENDENT_REP_OF_SUBLOCATION } from '../../../constants/CommonConstants';
import React, { useMemo } from 'react';

const ApprovedIndependentRep = ({ sublocation }) => {
  const classes = useStyles();
  let textName =
    sublocation?.independentRep?.firstName && sublocation?.independentRep?.lastName
      ? `${sublocation.independentRep?.firstName} ${sublocation.independentRep?.lastName}`
      : '';
  const independentRepOption = useMemo(() => {
    if (sublocation?.independentRep?.id) {
      return [
        {
          text: textName,
          value: sublocation?.independentRep?.id,
        },
      ];
    } else {
      return [];
    }
  }, [sublocation]);

  const rateTypeOptions = [
    { value: 'RATE_ONLY', text: 'Rate only' },
    { value: 'FIXED_ONLY', text: 'Fixed Only' },
    { value: null, text: '' },
    { value: 'BOTH', text: 'Both' },
  ];

  return (
    <GridContainer spacing={1} className={classes.independentRepContainer}>
      <GridItem xs={12} className={classes.independentRepTitle}>
        <Typography variant="subtitle9">{'Independent Rep'}</Typography>
      </GridItem>
      <GridItem className={classes.independentRepForm} xs={12}>
        <GridContainer spacing={1}>
          <GridItem xs={12} sm={6} md={3} pb={1}>
            <Select
              inputLabel={INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}
              options={independentRepOption}
              value={sublocation[`${INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}_${sublocation?.id}`]}
              onChange={() => {}}
              disabled={true}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} pb={1}>
            <Select
              inputLabel="Rate Type"
              options={rateTypeOptions}
              value={sublocation[`${INDEPENDENT_REP_OF_SUBLOCATION.PAY_CODE}_${sublocation?.id}`]}
              onChange={() => {}}
              disabled={true}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} pb={1}>
            <TextField
              label={'Fee'}
              value={sublocation[`${INDEPENDENT_REP_OF_SUBLOCATION.FEE}_${sublocation?.id}`]}
              disabled={true}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} pb={1}>
            <PercentTextField
              label={'Rate'}
              value={sublocation[`${INDEPENDENT_REP_OF_SUBLOCATION.RATE}_${sublocation?.id}`]}
              disabled={true}
              useFormik={false}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default React.memo(ApprovedIndependentRep);
