import { useMemo, useEffect, useState, useCallback } from 'react';
import ReactTable from '../../../components/shared/ReactTable/ReactTable';
import { useMediaQuery, useTheme } from '@mui/material';
import { COLUMNS, MODEL_TABLE_DYNAMIC } from '../../../constants/tableColumns';
import { useGetModelsQuery } from '../../../services/Models/ModelsService';
import ModelAccordion from './ModelAccordion';
import { MODEL_DETAIL_LABEL } from '../../../constants/accordionFields';
import { Link } from '../../../components/shared';
import { routeConstants } from '../../../constants/routeConstants';
import LoadingSpinner from '../../../components/LoadingSpinner';
import NoContent from '../../../components/shared/NoContent/NoContent';
import { useDispatch } from 'react-redux';
import { setActiveModelsFilters } from '../../../redux-slice/modelsFilters';

const ModelTable = ({ searchParams, onTotalLoad, tab, activeModels }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isBelowMD = useMediaQuery(theme.breakpoints.down('md'));
  const [currentPage, setCurrentPage] = useState(tab === 'active' && activeModels?.pageNumber);
  const [perPageNumber, setPerPageNumber] = useState(tab === 'active' && activeModels?.rowsPerPage);
  const [tableData, setTableData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchFilterData, setSearchFilterData] = useState(searchParams);
  const [skipCall, setSkipCall] = useState(false);

  const {
    currentData: latestData,
    isError,
    isSuccess,
    isLoading,
    error,
    isFetching,
  } = useGetModelsQuery(
    {
      searchParams: searchFilterData,
      page: currentPage,
      perPage: perPageNumber,
    },
    { skip: skipCall },
  );

  const columns = useMemo(() => {
    const dynamicColumns = MODEL_TABLE_DYNAMIC(isBelowMD);
    const col = [COLUMNS.EXPAND_ICON_COLUMN, ...dynamicColumns];
    return col;
  }, [isBelowMD]);

  const locationLink = useCallback(
    (id, name) => (
      <Link underline="none" to={`/${routeConstants.MODELS_ROUTE}/${id}`}>
        {name}
      </Link>
    ),
    [],
  );
  useEffect(() => {
    setSkipCall(true);
    setSearchFilterData(searchParams);
    setCurrentPage(tab === 'active' && activeModels?.pageNumber);
    setPerPageNumber(tab === 'active' && activeModels?.rowsPerPage);
    setSkipCall(false);
  }, [searchParams]);

  useEffect(() => {
    if (isSuccess && latestData) {
      let total = latestData?.pagination?.total || 0;
      const data = latestData?.data?.models || [];
      const models = data.map((item) => ({
        id: item.id,
        modelId: item.p9ModelLegacyNumber || 'N/A',
        modelTitle: item.title ? locationLink(item.id, item.title) : 'N/A',
        manufacturer: item?.manufacturer?.name || 'N/A',
        theme: item?.theme?.name || 'N/A',
        subTheme: item?.theme?.subTheme || 'N/A',
        category: item?.category?.name || 'N/A',
        [MODEL_DETAIL_LABEL.LIST.YEAR_MADE]: item?.yearMade || 'N/A',
        [MODEL_DETAIL_LABEL.LIST.METER]: item?.meter?.type || 'N/A',
        [MODEL_DETAIL_LABEL.LIST.HEIGHT]: item?.height || 'N/A',
        [MODEL_DETAIL_LABEL.LIST.WIDTH]: item?.width || 'N/A',
        [MODEL_DETAIL_LABEL.LIST.WEIGHT]: item?.weight || 'N/A',
      }));
      setTableData(models);
      setTotalCount(total);
      onTotalLoad(total ?? totalCount);
    } else if (isError) {
      onTotalLoad(0);
    }
  }, [isError, isSuccess, latestData]);

  const pageChangeHandler = (currentPage) => {
    dispatch(
      tab === 'active' &&
        setActiveModelsFilters({ ...activeModels, pageNumber: currentPage, rowsPerPage: perPageNumber }),
    );
    setCurrentPage(currentPage);
  };

  const perPageChangeHandler = (newPerPage) => {
    dispatch(
      tab === 'active' && setActiveModelsFilters({ ...activeModels, rowsPerPage: newPerPage, pageNumber: currentPage }),
    );
    setPerPageNumber(newPerPage);
  };

  const detailModelComponent = ({ row }) => {
    const original = row?.original;
    return original && <ModelAccordion data={original} />;
  };

  return (
    <>
      {(isLoading || isFetching) && <LoadingSpinner containerHeight={300} />}
      {isError && (
        <NoContent
          title="No Model Found"
          desc={(isError && error?.data?.message) || <>There are no model present with this filter.</>}
        />
      )}
      {!isLoading && !isError && !isFetching && tableData && (
        <ReactTable
          data={tableData}
          columns={columns}
          pagination
          manualPagination
          totalCount={totalCount}
          pageNumberForTablePagination={currentPage - 1}
          onPageChange={pageChangeHandler}
          onPerPageChange={perPageChangeHandler}
          perPage={perPageNumber}
          maxWidth="xl"
          expandable
          renderRowSubComponent={detailModelComponent}
        />
      )}
    </>
  );
};

export default ModelTable;
