import React, { useMemo, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import NewFilterDrawer from '../../../components/shared/FilterDrawer/NewFilterDrawer';
import SearchBar from '../../../components/SearchBar';
import FILTER_CONSTANTS, { FILTER_VALUES } from '../../../constants/FilterConstants';
import useStyles from './style';
import ModelTable from './ModelTable';
import {
  useLazyGetModelCategoryQuery,
  useLazyGetModelThemeQuery,
  useLazyGetModelManufacturerQuery,
} from '../../../services/Models/ModelsService';
import { useSearchFilterParam } from '../../../hooks';
import { resetActiveModelsFiltersState, setActiveModelsFilters } from '../../../redux-slice/modelsFilters';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from '../../../constants/pagination';

const ActiveTab = ({ onTotalLoad }) => {
  const classes = useStyles();
  const { DRAWER_TYPES, TITLE, FILTER_DATA } = FILTER_CONSTANTS;
  const dispatch = useDispatch();
  const { activeModels } = useSelector((state) => state.modelsFilter);

  const [searchText, setSearchText] = useState('');
  const [filterParams, setFilterParams] = useState(FILTER_DATA.MODEL.INITIAL_PARAMS);
  const [getModelCategory] = useLazyGetModelCategoryQuery();
  const [getModelTheme] = useLazyGetModelThemeQuery();
  const [getModelManufacturer] = useLazyGetModelManufacturerQuery();

  const getAPI = (value) => {
    switch (value) {
      case FILTER_VALUES.CATEGORY:
        return getModelCategory;
      case FILTER_VALUES.THEME:
        return getModelTheme;
      case FILTER_VALUES.MANUFACTURER:
        return getModelManufacturer;

      default:
        return [];
    }
  };

  const filterData = useMemo(
    () =>
      FILTER_DATA.MODEL.FILTER.map((item) => ({
        ...item,
        api: getAPI(item.value),
      })),
    [],
  );

  useEffect(() => {
    const storedFilterParams = {
      [FILTER_VALUES.CATEGORY]: [],
      [FILTER_VALUES.THEME]: [],
      [FILTER_VALUES.MANUFACTURER]: [],
    };

    if (activeModels[FILTER_VALUES.CATEGORY] && activeModels[FILTER_VALUES.CATEGORY]?.length > 0) {
      storedFilterParams.categoryId = activeModels[FILTER_VALUES.CATEGORY];
    }
    if (activeModels[FILTER_VALUES.THEME] && activeModels[FILTER_VALUES.THEME]?.length > 0) {
      storedFilterParams[FILTER_VALUES.THEME] = activeModels[FILTER_VALUES.THEME];
    }
    if (activeModels[FILTER_VALUES.MANUFACTURER] && activeModels[FILTER_VALUES.MANUFACTURER]?.length > 0) {
      storedFilterParams.manufactureId = activeModels[FILTER_VALUES.MANUFACTURER];
    }
    setSearchText(activeModels.searchText);
    setFilterParams(storedFilterParams);
  }, [activeModels]);

  const searchParams = useSearchFilterParam({
    filterKey: FILTER_DATA.MODEL,
    filterParams,
    searchText,
  });

  const searchHandler = (params) => {
    setSearchText(params);
    dispatch(
      setActiveModelsFilters({
        ...activeModels,
        searchText: params,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const onReset = () => {
    setSearchText('');
    dispatch(
      setActiveModelsFilters({
        ...activeModels,
        searchText: '',
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
  };

  const resetFilterParams = () => {
    setFilterParams(FILTER_DATA.MODEL.INITIAL_PARAMS);
    dispatch(resetActiveModelsFiltersState());
  };

  const applyFilterHandler = (filterValues) => {
    dispatch(
      setActiveModelsFilters({
        ...activeModels,
        ...filterValues,
        pageNumber: DEFAULT_PAGE,
        rowsPerPage: DEFAULT_PER_PAGE,
      }),
    );
    setFilterParams(filterValues);
  };

  return (
    <>
      <Box className={classes.searchFilterWrap}>
        <Box className={classes.searchWrap}>
          <SearchBar
            placeholder={'Search By Model Title'}
            onSearch={searchHandler}
            onReset={onReset}
            searchText={searchText}
          />
        </Box>

        <Box px={3}>
          <NewFilterDrawer
            filteredValues={filterParams}
            name={TITLE.FILTERS.toUpperCase()}
            title={TITLE.FILTERS}
            drawerType={DRAWER_TYPES.FILTER_DRAWER}
            onReset={resetFilterParams}
            onApplyFilter={(v) => {
              applyFilterHandler(v);
            }}
            filterData={filterData}
            emptyFilterLength={JSON.stringify(FILTER_DATA.MODEL.INITIAL_PARAMS).length}
          />
        </Box>
      </Box>
      <ModelTable searchParams={searchParams} onTotalLoad={onTotalLoad} activeModels={activeModels} tab="active" />
    </>
  );
};

export default ActiveTab;
