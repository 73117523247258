import { Typography } from '@mui/material';
import { GridContainer, GridItem, Autocomplete, Select, PercentTextField } from '../../../components/shared';

import { PerformantTextField } from '../../../components/shared/PerformantTextField/PerformantTextField';

import useStyles from '../style';
import { useFormikContext } from 'formik';
import { useGetDropdownUsersQuery } from '../../../services/Users/UsersService';
import { useDebounce, useHasRoles } from '../../../hooks';
import { useEffect, useMemo, useState } from 'react';
import { INDEPENDENT_REP_OF_SUBLOCATION } from '../../../constants/CommonConstants';
import { ROLE } from '../../../constants/roles';
import { RATE_TYPE_OPTIONS, RATE_TYPES } from '../../../constants/RatesConstants';

const IndependentRep = ({ sublocation }) => {
  const independentRepName = sublocation?.independentRep?.firstName
    ? `${sublocation?.independentRep?.firstName} ${sublocation?.independentRep?.lastName}`
    : '';
  const classes = useStyles();
  const { values, getFieldProps, setFieldValue } = useFormikContext();
  const [independentRepInputValue, setIndependentRepInputValue] = useState('');
  const [independentRepId, setIndependentRepId] = useState({});
  const [payCode, setPayCode] = useState('');

  useEffect(() => {
    setIndependentRepId(getFieldProps(`${INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}_${sublocation?.id}`)?.value);
    setPayCode(getFieldProps(`${INDEPENDENT_REP_OF_SUBLOCATION.PAY_CODE}_${sublocation?.id}`)?.value);
  }, [values]);

  const isSuperAdminOrAccountingUser = useHasRoles([ROLE.ACCOUNTING, ROLE.SUPER_ADMIN_IT]);
  const nonEditable = useMemo(() => {
    if (!isSuperAdminOrAccountingUser) {
      return true;
    } else {
      return false;
    }
  }, [isSuperAdminOrAccountingUser]);
  const { currentData: independentRepResponse, isSuccess: independentRepIsSuccess } = useGetDropdownUsersQuery(
    {
      searchParams: `q=${useDebounce(independentRepInputValue, 600)}&status=true`,
      perPage: 100,
      page: 1,
    },
    {
      skip:
        nonEditable ||
        (independentRepInputValue === independentRepName && independentRepName !== '') ||
        (independentRepInputValue === independentRepId?.text && independentRepInputValue !== '') ||
        (independentRepName === independentRepId?.text && independentRepName !== ''),
    },
  );

  const independentRepOptions = useMemo(() => {
    if (independentRepIsSuccess && independentRepResponse?.users) {
      const options = independentRepResponse?.users.map((user) => {
        const option = { text: `${user.firstName} ${user.lastName}`, value: user.id };
        return option;
      });
      return options;
    } else {
      return [];
    }
  }, [independentRepIsSuccess, independentRepResponse]);

  const onChangeRateTypeHandler = (e) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    if (value === RATE_TYPES.None) {
      setFieldValue(`${INDEPENDENT_REP_OF_SUBLOCATION.FEE}_${sublocation?.id}`, '0');
      setFieldValue(`${INDEPENDENT_REP_OF_SUBLOCATION.RATE}_${sublocation?.id}`, '0');
    }
    setFieldValue(name, value);
  };

  return (
    <GridContainer spacing={1} className={classes.independentRepContainer}>
      <GridItem xs={12} className={classes.independentRepTitle}>
        <Typography variant="subtitle9">{'Independent Rep'}</Typography>
      </GridItem>
      <GridItem className={classes.independentRepForm} xs={12}>
        <GridContainer spacing={1}>
          <GridItem xs={12} sm={6} md={3} pb={1}>
            <Autocomplete
              label={'Independent Rep'}
              value={independentRepId}
              options={independentRepOptions}
              name={`${INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}_${sublocation?.id}`}
              getOptionLabel={(option) =>
                option?.text ||
                (values ? values[`${INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}_${sublocation?.id}`]?.text ?? '' : '')
              }
              onInputChange={(event, newInputValue) => {
                setIndependentRepInputValue(newInputValue);
              }}
              onChange={(e, value) => {
                setFieldValue(`${INDEPENDENT_REP_OF_SUBLOCATION.REP_ID}_${sublocation?.id}`, value);
              }}
              disabled={nonEditable}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} pb={1}>
            <Select
              inputLabel="Rate Type"
              options={RATE_TYPE_OPTIONS}
              {...getFieldProps(`${INDEPENDENT_REP_OF_SUBLOCATION.PAY_CODE}_${sublocation?.id}`)}
              value={payCode}
              onChange={onChangeRateTypeHandler}
              disabled={nonEditable}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} pb={1}>
            <PerformantTextField
              label={'Fee'}
              {...getFieldProps(`${INDEPENDENT_REP_OF_SUBLOCATION.FEE}_${sublocation?.id}`)}
              disabled={nonEditable || payCode === RATE_TYPES.None}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={3} pb={1}>
            <PercentTextField
              label={'Rate'}
              useFormik={true}
              {...getFieldProps(`${INDEPENDENT_REP_OF_SUBLOCATION.RATE}_${sublocation?.id}`)}
              disabled={nonEditable || payCode === RATE_TYPES.None}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default IndependentRep;
