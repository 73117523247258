import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';

export const PercentTextField = React.memo((props) => {
  const {
    label,
    loading,
    disabled,
    useFormik = false,
    helperText,
    value: propValue,
    onChange,
    error = false,
    ...otherProps
  } = props;

  let field, meta, setFieldValue;
  let formikValue = 0;

  if (useFormik) {
    [field, meta] = useField(props);
    const formik = useFormikContext();
    setFieldValue = formik?.setFieldValue;
    formikValue = field?.value || 0;
  }

  const initialValue = useFormik ? formikValue : propValue || 0;
  const [currentFieldValue, setCurrentFieldValue] = useState(initialValue === 0 ? '' : (initialValue * 100).toFixed(0));

  // Sync the local state when the external value changes
  useEffect(() => {
    const externalValue = useFormik ? formikValue : propValue;
    const newValue = (externalValue || 0) * 100;
    setCurrentFieldValue(externalValue === 0 ? '' : newValue.toFixed(0));
  }, [formikValue, propValue, useFormik]);

  const handleChange = (evt) => {
    let inputValue = evt.target.value;

    // Remove % and restrict to valid number input
    inputValue = inputValue.replace('%', '').replace(/[^0-9.]/g, '');

    // Allow deletion by checking if inputValue is empty
    if (inputValue === '') {
      setCurrentFieldValue(() => inputValue);
      if (useFormik && setFieldValue) {
        setFieldValue(field.name, '');
      }
      return;
    }

    let parsedValue = parseInt(inputValue, 10);
    setCurrentFieldValue(parsedValue.toString());

    if (useFormik && setFieldValue) {
      const decimalValue = parsedValue / 100;
      setFieldValue(field.name, decimalValue);
    }
  };

  const handleBlur = () => {
    let decimalValue = parseFloat(currentFieldValue) / 100 || 0;
    setCurrentFieldValue((decimalValue * 100).toFixed(0));

    if (useFormik && setFieldValue) {
      setFieldValue(field.name, decimalValue);
    } else if (onChange) {
      onChange({ name: props.name, value: decimalValue });
    }
  };

  return (
    <TextField
      {...(useFormik ? field : {})}
      {...otherProps}
      label={label || 'Label'}
      value={loading ? 'Loading...' : currentFieldValue}
      onChange={!disabled ? handleChange : undefined}
      onBlur={!disabled ? handleBlur : undefined}
      error={useFormik ? meta?.touched && Boolean(meta.error) : error}
      helperText={useFormik ? (meta?.touched && meta?.error ? meta.error : helperText) : helperText}
      disabled={disabled}
      inputProps={{
        maxLength: 2,
      }}
      InputProps={{
        endAdornment: <span>%</span>,
      }}
    />
  );
});

PercentTextField.displayName = 'PercentTextField';
