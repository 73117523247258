import { Box, CircularProgress, Paper } from '@mui/material';
import useStyles from './style/style';

const displayName = 'LoadingSpinner';

const LoadingSpinner = (props) => {
  const { containerHeight = 200, isPaper = false, withBackground = false } = props;
  const classes = useStyles();
  const loaderStyle = withBackground ? { backgroundColor: 'rgba(255, 255, 255, 0.9)', width: '85px !important' } : {};
  return (
    <Box
      data-testid="loadingSpinner"
      className={classes.root}
      component={isPaper ? Paper : 'div'}
      sx={{
        minHeight: containerHeight,
        zIndex: 10001,
        ...loaderStyle,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

LoadingSpinner.displayName = displayName;
export default LoadingSpinner;
